/* ===== BUTTONS ===== */
.btn {
  text-transform: uppercase;
  &.btn-square {
    border-radius: 0px;
  }
  &.btn-pill {
    border-radius: 20px;
    @include media-breakpoint-up(md){
      border-radius: 30px;
    }
  }
  padding: 0.3125rem  1rem;
  @include media-breakpoint-up(md){
    padding: 0.56rem 1.5rem;
  }
}

/* Button Circle */
.btn-rounded-circle {
  border-radius: 50%;
}

/* Button Icon */
.btn-icon {
  width: 43px;
  height: 43px;
  padding:  0.312rem 0.625rem;
  color: $white;
  font-size: 1.25rem;
  &:hover {
    color: $white;
  }
}

/* Button Refress */
.btn-refress {
  background: $gray-200;
  padding: 0 0.375rem;
  font-size: 1.0625rem;
}

/* Social Button */
//Facebook
.facebook {
  background-color: $facebook;
  color: $white;
  &.btn-outline {
    border-color: rgba($facebook, 0.6);
    background-color: transparent;
    color: $facebook;
    &:hover {
      background-color: $facebook;
      color: $white;
    }
  }
  &:hover {
    background-color: darken($facebook, 5%);
    color: $white;
  }
}

//Twitter
.twitter {
  background-color: $twitter;
  color: $white;
  &.btn-outline {
    border-color: rgba($twitter, 0.6);
    background-color: transparent;
    color: $twitter;
    &:hover {
      background-color: $twitter;
      color: $white;
    }
  }
  &:hover {
    background-color: darken($twitter, 5%);
    color: $white;
  }
}

//Google Plus
.google-plus {
  background-color: $google-plus;
  color: $white;
  &.btn-outline {
    border-color: rgba($google-plus, 0.6);
    background-color: transparent;
    color: $google-plus;
    &:hover {
      background-color: $google-plus;
      color: $white;
    }
  }
  &:hover {
    background-color: darken($google-plus, 5%);
    color: $white;
  }
}

//Linkedin
.linkedin {
  background-color: $linkedin;
  color: $white;
  &.btn-outline {
    border-color: rgba($linkedin, 0.6);
    background-color: transparent;
    color: $linkedin;
    &:hover {
      background-color: $linkedin;
      color: $white;
    }
  }
  &:hover {
    background-color: darken($linkedin, 5%);
    color: $white;
  }
}

//Pinterest
.pinterest {
  background-color: $pinterest;
  color: $white;
  &.btn-outline {
    border-color: rgba($pinterest, 0.6);
    background-color: transparent;
    color: $pinterest;
    &:hover {
      background-color: $pinterest;
      color: $white;
    }
  }
  &:hover {
    background-color: darken($pinterest, 5%);
    color: $white;
  }
}

//Tumblr
.tumblr {
  background-color: $tumblr;
  color: $white;
  &.btn-outline {
    border-color: rgba($tumblr, 0.6);
    background-color: transparent;
    color: $tumblr;
    &:hover {
      background-color: $tumblr;
      color: $white;
    }
  }
  &:hover {
    background-color: darken($tumblr, 5%);
    color: $white;
  }
}

//Vimeo
.vimeo {
  background-color: $vimeo;
  color: $white;
  &.btn-outline {
    border-color: rgba($vimeo, 0.6);
    background-color: transparent;
    color: $vimeo;
    &:hover {
      background-color: $vimeo;
      color: $white;
    }
  }
  &:hover {
    background-color: darken($vimeo, 5%);
    color: $white;
  }
}

//Dropbox
.dropbox {
  background-color: $dropbox;
  color: $white;
  &.btn-outline {
    border-color: rgba($dropbox, 0.6);
    background-color: transparent;
    color: $dropbox;
    &:hover {
      background-color: $dropbox;
      color: $white;
    }
  }
  &:hover {
    background-color: darken($dropbox, 5%);
    color: $white;
  }
}

//Dribbble
.dribbble {
  background-color: $dribbble;
  color: $white;
  &.btn-outline {
    border-color: rgba($dribbble, 0.6);
    background-color: transparent;
    color: $dribbble;
    &:hover {
      background-color: $dribbble;
      color: $white;
    }
  }
  &:hover {
    background-color: darken($dribbble, 5%);
    color: $white;
  }
}

//Skype
.skype {
  background-color: $skype;
  color: $white;
  &.btn-outline {
    border-color: rgba($skype, 0.6);
    background-color: transparent;
    color: $skype;
    &:hover {
      background-color: $skype;
      color: $white;
    }
  }
  &:hover {
    background-color: darken($skype, 5%);
    color: $white;
  }
}

