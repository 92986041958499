/*===== LIST GROUP ITEM =====*/
.list-group-item {
  color: $dark;
  p {
    font-size: 0.8125rem;
    color: $text-color;
  }
  .media-body {
    padding-right: 1.5rem;
  }
}

.list-underline {
  display: flex;
  flex-direction: column;
  .list-item {
    border-bottom: 1px solid $border-color;
    display: flex;
    justify-content: space-between;
    padding-top: 0.75rem;
    padding-bottom: 1.25rem;
    color: $dark;
  }
}
