/*===== MEDIA =====*/
/* Media Small */
.media-sm {
  margin-bottom: 1.5rem;
  .media-sm-wrapper {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 1rem;
    img {
      border-radius: 50%;
    }
    i {
      text-align: center;
      line-height: 50px;
      display: block;
      color: $white;
      font-size: 1.5rem;
    }
    .active {
      position: absolute;
      bottom: -3px;
      right: 0px;
      border: 3px solid $white;
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
  }
  a {
    color: $text-color;
  }
  .title {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    color: $dark;
    margin-bottom: 0.25rem;
  }
  .discribe {
    display: block;
    white-space: nowrap;
    width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .time {
    time {
      font-size: 0.75rem;
      font-style: italic;
      font-weight: 700;
    }
  }
}


/* Media Chat */
.media-chat {
  margin-bottom: 1.25rem;
  img {
    margin-right: 0.75rem;
  }
  .media-body {
    flex: unset;
  }
  .text-content {

    min-width: 5%;
    padding: 1.25rem;
    margin-bottom: 0.5rem;
    background-color: $primary;
    border-top-left-radius: 0px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 0;

    &:first-child {
      border-top-left-radius: 25px;
    }
    &:last-child:not(:first-child) {
      border-bottom-left-radius: 25px;
    }

  }
  .message {
    display: block;
    font-size: 0.88rem;
    color: $white;
    font-weight: 700;
  }
  .time {
    color: rgba($white, .6);
  }

  /* Media Chat Right */
  &.media-chat-right {
    justify-content: flex-end;
    img {
      margin-right: 0;
    }
    .media-body {
      margin-right: 0.75rem;
    }
    .text-content {
      box-shadow: 0px 0px 18px 0px rgba(103, 68, 149, 0.1);
      background-color: $white;
      border-bottom-left-radius: 25px;
      border-top-left-radius:  25px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;

      &:first-child {
        border-top-right-radius: 25px;
        border-bottom-left-radius: 25px;
      }

      &:last-child:not(:first-child) {
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
      }
    }
    .message {
      color: $dark;
    }
    .time {
      color: $text-color;
    }

  }
}

