/*===== VECTOR MAP =====*/
/* US Vector Map */
#us-vector-map-marker {
  height: 260px;
}

.vec-map-wrapper {
  height: 180px;
  @include media-breakpoint-up(md){
    height: 225px
  }
  @include media-breakpoint-up(lg){
    height: 325px
  }
}

/* Vector Map Zoom In And Zoom Out Button */
.jvectormap-zoomin,
.jvectormap-zoomout {
  background-color: $primary;
}
.jvectormap-marker.jvectormap-element {
  fill: $black;
  stroke: $black;
}


.map-container {
  height: 400px;
  width: 100%;
  margin: 0;
  padding: 0;
}
.info-title {
  color: $dark;
  padding: 0.31rem;
}
