/*===== FOOTER =====*/
.copyright {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  @include media-breakpoint-up(lg){
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  p {
    font-weight: 500;
  }
}
