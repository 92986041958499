/*===== HEADER =====*/
.main-header {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1020;
  margin-bottom: 0;
  padding: 0;
  transition: box-shadow .2s linear;
}

/* Navbar */
.navbar {
  margin-bottom: 0;
  border: none;
  min-height: 75px;
  border-radius: 0;
  padding: 0;
  border-bottom: 1px solid darken($border-color, 9);
  @include transition(margin-left .3s ease-in-out);

  @include media-breakpoint-up(lg) {
    padding-right: 1rem;
  }
  .sidebar-offcanvas-toggle,
  .sidebar-toggle {
    background-color: transparent;
    background-image: none;
    color: $light;
    font-size: 2rem;
    padding: 0 1rem;
    border: 0px;
    height: 75px;
    cursor: pointer;
    @include media-breakpoint-up(lg) {
      padding: 0 1.44rem 0 1.75rem;
    }
    &:before {
      content: "\f35c";
      font-family: $font-family-icon;
    }
  }

  /* Page Title */
  .page-title {
    max-width: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: capitalize;
    color: $light;
    font-size: 1.125rem;
    white-space: nowrap;
    @include media-breakpoint-up(md){
      font-size: 1.625rem;
      max-width: 150px;
    }
    @include media-breakpoint-up(xl){
      max-width: unset;
    }
  }
  /* Search Form */
  .search-form {
    display: none;
    position: relative;
    @include media-breakpoint-up(lg){
      display: block;
    }
    .input-group {
        margin-bottom: 0;
        border-radius: .5rem;
        align-items: center;
        background-color: $gray-100;
        @include media-breakpoint-up(xxl){
        }
        .btn {
          padding: 0px 8px;
          border: 1px solid $border-color;
          border-radius: 3px;
          background-color: $white;
          font-size: 1rem;
          color: $dark;
        }
        .input-group-append {
          @include media-breakpoint-up(md) {
            padding-right: .625rem;
          }
        }

      input.form-control {
        border: none;
        background: transparent;
        font-size: 0.9375rem;
        padding: 1.375rem 1.25rem;
        display: none;
        @include media-breakpoint-up(lg) {
          display: block;
          min-width: 100px;
        }
        @include media-breakpoint-up(xxl) {
          min-width: 335px;
        }
        &:focus {
          box-shadow: none;
        }
      }

      #search-results-container{
        width: 100%;
        background: #fff;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        box-shadow: 1px 2px 5px #e5e9f2;
        li{
          padding: 20px 30px;
          .link{
            font-size: 1rem;
          }
        }
      }
    }
    @include media-breakpoint-up(xl) {
      position: relative;
      display: block;
      @include media-breakpoint-up(lg) {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

  }
  /* Navbar Nav */
  .navbar-nav {
    flex-wrap: nowrap;
    padding-left: .5rem;
    padding-right: .5rem;
    @include media-breakpoint-up(md) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    @include media-breakpoint-up(lg) {
      align-items: center;
      padding-left: 0;
      padding-right: 0;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
    .nav-item {
      padding-top: .5rem;
      padding-bottom: .5rem;
      @include media-breakpoint-up(lg) {
        padding-top: 1.1875rem;
        padding-bottom: 1.1875rem;
      }
      .nav-link {
        color: $secondary;
      }
    }
  }
  /* Navbar Collapse */
  .navbar-collapse {
    order: 1;
    border-top: 1px solid $border-color;
    @include media-breakpoint-up(lg) {
      order: 0;
      border-top: none;
    }
  }
  /* Navbar Right */
  .navbar-right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: auto;

    .navbar-nav {
      flex-direction: row;
      align-items: center;
      @include media-breakpoint-up(lg) {
        padding-left: 0;
        padding-right: 0;
      }
      @include media-breakpoint-up(xl) {
        padding-left: 0;
        padding-right: 0;
      }
      .dropdown-toggle {
        padding-left: 1rem;
        padding-right: 1rem;
        i {
          font-size: 1.75rem;
          color: $dark;
        }
      }
      .dropdown-menu {
        position: absolute;
        right: 8px;
        border-color: $border-color;

        @include media-breakpoint-up(md){
          right: 0px;
        }

        @include media-breakpoint-up(lg){
          right: 15px;
        }
      }
      .user-menu {
        @include media-breakpoint-up(lg) {
          min-width: 160px;
        }
        .user-image {
          width: 40px;
          border-radius: .25rem;
        }
        .dropdown-link-item {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          .nav-text {
            margin-right: auto;
          }
        }
        .dropdown-menu {
          width: 210px;
          border-radius: 5px;
        }

        .dropdown-toggle {
          font-size: 0.98rem;
          padding: 0 .5rem;
          line-height: 75px;
          display: flex;
          align-items: center;
          >span {
            color: $gray-900;
            padding: 0 .5rem;
            font-size: 1rem;
            font-weight: 700;
            @include media-breakpoint-up(lg){
              text-overflow: ellipsis;
              max-width: 70px;
              overflow: hidden;
            }
            @include media-breakpoint-up(xl){
              max-width: 120px;
            }
            @include media-breakpoint-up(xxl){
              max-width: 200px;
            }
          }
          @include media-breakpoint-up(lg) {
            padding-right: .25rem;
            padding-left: .25rem;
          }
          @include media-breakpoint-up(xl) {
            padding-right: 1rem;
            padding-left: 1rem;
          }
        }
        .dropdown-toggle:after {
          border: none;
          color: $light;
          font-family: "Material Design Icons";
          content: '\f140';
          vertical-align: -3px;
          font-size: 1.25rem;
          transform: rotate(0);
          transition: transform .3s ease-in-out;
          @include media-breakpoint-up(md){
            margin-right: -7px;
          }
          @include media-breakpoint-up(lg){
            // margin-right: -7px;
          }
          @include media-breakpoint-up(xl){
            margin-right: -5px;
          }

        }
        &.show {
          .dropdown-toggle:after {
            transform: rotate(180deg);
            transition: transform .3s ease-in-out;
          }
        }
      }

    }
  }
  .dropdown-menu {
    width: 215px;
    padding: 0 0 0 0;
    margin: 0;
    top: 100%;
    border-color: transparent;
    @include media-breakpoint-up(md) {
      width: 250px;
    }
    @include media-breakpoint-up(lg) {
      border-color: $border-color;
    }

    >li a {
      color: $text-color;
      font-size: 0.88rem;
      padding: 0.25rem 1.25rem;
      >i {

        margin-right: 0.625rem;
        font-size: 1.25rem;
      }
    }
    li.dropdown-footer {
      border-bottom-left-radius: .25rem;
      border-bottom-right-radius: .25rem;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      background-color: $white;
      border-top: 1px solid $border-color;
      margin-top: 0.625rem;
      >a {
        padding: 0.94rem 1.25rem;
      }
    }
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: $secondary;
    background-color: transparent;
  }
}

@media (max-width: 767px) {
  .main-header {
    position: relative;
  }
  .main-header .logo,
  .main-header .navbar {
    width: 100%;
  }
  .main-header .navbar {
    margin: 0;
  }
}



/* Header Fix */
.navbar-fixed {
  .page-wrapper {
    @include media-breakpoint-up(md) {
      padding-top: 4.8125rem;
    }
    .main-header {
      @include media-breakpoint-up(md) {
        position: fixed; // padding-left: 15.6rem;
      }
    }
  }
}

/*  Header Dark  */
.navbar.navbar-dark {
  background-color: $black-russian;
  border-color: transparent;
  .sidebar-toggle {
    color: $white-light;
  }
  .page-title {
    color: $white-light;
  }
  .custom-dropdown {
    .custom-dropdown-toggler {
      .icon {
        color: $white-light;
      }
    }
  }

  .navbar-right .navbar-nav .user-menu .dropdown-toggle>span {
    color: $white-light;
  }

  .navbar-right .navbar-nav .user-menu .dropdown-toggle {
    color: $white-light;
  }

  .search-form .input-group {
    background-color: lighten($black-russian, 10%);
  }
  .search-form .input-group .btn {
    background-color: $black-russian;
    border-color: $black-russian;
    color: $white-light;
  }
  .search-form .input-group input.form-control {
    color: $white-light;
  }
  input::placeholder {
    color: $white-light;
  }
}

/* Header Light */
.navbar.navbar-light {
  background-color: $white;
  border-color: $border-color;
  .sidebar-toggle {
    border-color: $border-color;
  }
  .sidebar-offcanvas-toggle {
    border-color: $border-color;
  }
  .search-form .btn,
  .search-form .input-group input.form-control {
    color: $text-color;
  }
  .navbar-right .navbar-nav .notifications-menu .dropdown-toggle {
    border-color: $border-color;
  }
  .navbar-right .navbar-nav .user-menu .dropdown-toggle>span {
    color: $light;
  }
  .search-form {
    .input-group {
      @include media-breakpoint-up(xl){
        border: 1px solid $border-color;
      }
    }
  }
}

/* Header Primary */
.navbar.navbar-primary {
  background-color: $primary;
  border-color: transparent;
  .sidebar-toggle {
    color: $white;
  }
  .page-title {
    color: $white;
  }
  .custom-dropdown {
    .custom-dropdown-toggler {
      .icon {
        color: $white;
      }
    }
  }

  .navbar-right .navbar-nav .user-menu .dropdown-toggle>span {
    color: $white;
  }

  .navbar-right .navbar-nav .user-menu .dropdown-toggle {
    color: $white ;
  }

  .search-form .input-group {
    background-color: lighten($primary, 10%);
  }
  .search-form .input-group .btn {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }
  .search-form .input-group input.form-control {
    color: $white;
  }
  input::placeholder {
    color: $white;
  }
}

/* Navbar Transparent */
.navbar.navbar-transparent {
  .search-form .input-group {
    background-color: $white;
  }
  .search-form .input-group .btn {
    background-color: transparent;
    border-color: $border-color;
    color: $text-color;
  }
  .search-form .input-group input.form-control {
    color: $text-color;
  }
  input::placeholder {
    color: $text-color;
  }
}

/* Cusmtom Dropdown */
.custom-dropdown {
  position: relative;
  padding-left: .5rem;
  padding-right: .5rem;
  @include media-breakpoint-up(md){
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .custom-dropdown-toggler {
    .icon {
      font-size: 1.75rem;
      color: $gray-900;
    }
    .badge-xs {
      position: absolute;
      top: 4px;
      right: 8px;
      background-color: $secondary;
      min-width: 18px;
      min-height: 18px;
      color: $white;
      font-size: 0.75rem;
      font-weight: 700;
      padding: 3px 5px;
    }
  }
}


/* Notification */
.dropdown-notify {
  display: none;
  width: 320px;
  position: absolute;
  top: 59px;
  left:-204px;
  border-radius: 5px;
  box-shadow: 0px 1px 2px 0px rgba(28, 29, 36, 0.1),inset 0px -1px 0px 0px rgba(229, 230, 235, 0.1);
  background-color: $white;

  @include media-breakpoint-up(lg){
    left:50%;
    transform: translateX(-50%);
  }

  .slim-scroll-wrapper {
    height: 325px;
    overflow: hidden;
  }
}

