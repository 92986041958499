/*===== OFFCANVAS =====*/
.card-offcanvas {
  border-radius: 0;
  position: fixed;
  top:0px;
  right: 0;
  min-width: 320px;
  min-height: 100%;
  background-color: $white;
  box-shadow: -5px 0px 20px 0px rgba(0, 0, 0, 0.18);
  z-index: 1030;
  transform: translateX(350px);
  transition: transform .3s ease-in-out;
  .card-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 1.4375rem;
    padding-right: 1.25rem;
    padding-bottom: 1rem;
    padding-left: 1.25rem;
    background-color: transparent;
    border-bottom-color:  $border-color;
    h2 {
      font-size: 1.375rem;
      color: $dark;
    }
  }
  .card-body {
    padding: 1.25rem;
  }
  &.active {
    transform: translateX(0);
    transition: transform .3s ease-in-out;
  }
}

/* Offcanvas Overlay */
.offcanvas-overlay {
  opacity: 1;
  visibility: visible;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1025;
  background: rgba(0, 0, 0, .3);
  animation: k-animate-fade-in .3s linear 1;
}
