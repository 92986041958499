/*===== PROGRESS BAR =====*/
.progress-sm {
  height: 6px;
}

.progress-md {
  height: 10px;
}

.progress-white {
  background-color: rgba($dark, .1);
}


#nprogress .bar {
  background: $primary !important;
}
