.card-activity {

    font-size: 13px !important;

    .card-header {
        padding: 30px 30px 0px 30px;
        display: flex;
        justify-content: space-between;
        background-color: transparent;
        border-bottom: none;
        flex-wrap: wrap;
        text-transform: capitalize;
        h2 {
          font-size: 1.375rem;
          color: $dark;
          &::before {
            line-height: 1.375rem;
            margin-right: 0.5rem;
          }
        }
    }
}
    .card-activity .activity-icon {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #fff;
      border-radius: 100%;
      position: relative;
      z-index: 50; }


    .card-activity  .media-body {
      margin-left: 15px;
      padding-top: 10px; }
    .card-activity  .media {
      position: relative; }
      .card-activity  .media::before {
        content: '';
        position: absolute;
        top: 0;
        left: 25px;
        height: 100%;
        border-left: 1px solid #ced4da;
        z-index: 40; }
      .card-activity  .media h6 {
        color: #343a40; }
      .card-activity  .media p {
        margin-bottom: 5px;
        line-height: normal;
        font-size: 13px; }
      .card-activity  .media span {
        display: block;
        font-size: 12px; }
      .card-activity  .media + .media {
        padding-top: 20px; }
  

    //      .activity-card-footer {
    //         border-top: 1px solid #dee2e6;
    //         padding: 0;
    //         background-color: #f8f9fa; }
    //         @media (min-width: 768px) {
    //             .activity-card-footer {
    //             display: flex;
    //             align-items: center;
    //             justify-content: space-between; } }
    //             .activity-card-footer > a {
    //           display: block;
    //           padding: 15px;
    //           border-bottom: 1px solid #dee2e6;
    //           text-align: center; }
    //           @media (min-width: 768px) {
    //             .activity-card-footer > a {
    //               padding: 0 20px;
    //               border-bottom: 0;
    //               text-align: left; } }
    //               .activity-card-footer > div {
    //           display: flex; }
    //           @media (min-width: 768px) {
    //             .activity-card-footer > div {
    //               display: block; } }
    //               .activity-card-footer > div a {
    //             flex: 1;
    //             text-align: center;
    //             display: inline-block;
    //             padding: 12px 20px;
    //             border-left: 1px solid #dee2e6; }
    //             .activity-card-footer > div a:first-child {
    //               border-left-width: 0; }
    //               @media (min-width: 768px) {
    //                 .activity-card-footer > div a:first-child {
    //                   border-left-width: 1px; } }

                      


/* ------------------------------------------ */
/* ############### ERROR PAGE ############### */
/* --------------------------====------------ */
.page-error-wrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px; }
    .page-error-wrapper .error-title {
      font-size: 100px;
      font-weight: 700;
      line-height: 0.7;
      margin-bottom: 0;
      border-bottom: 2px solid #adb5bd;
      padding: 0 50px;
      margin-bottom: 10px;
      color: #343a40; }
      @media (min-width: 576px) {
        .page-error-wrapper .error-title {
          font-size: 130px; } }
    .page-error-wrapper .btn-error {
      padding-left: 25px;
      padding-right: 25px;
      border: 2px solid #1b84e7;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 1px; }
      .page-error-wrapper .btn-error:hover, .page-error-wrapper .btn-error:focus {
        color: #fff;
        background-color: #1b84e7; }
  
  .error-footer {
    margin-bottom: 0;
    font-size: 12px;
    color: #adb5bd; }
  
  /* --------------------------------------- */
  /* ############### PROFILE ############### */
  /* --------------------------------------- */
  .card-profile .card-body {
    padding: 30px; }
  
  .card-profile .media {
    align-items: flex-start;
    display: block; }
    @media (min-width: 768px) {
      .card-profile .media {
        display: flex; } }
  
  .card-profile .media-body {
    text-align: left;
    margin-top: 25px; }
    @media (min-width: 768px) {
      .card-profile .media-body {
        margin-top: 0;
        margin-left: 30px; } }
  
  .card-profile img {
    width: 120px;
    border-radius: 100%; }
  
  .card-profile .card-footer {
    border-top: 1px solid #dee2e6;
    padding: 0;
    background-color: #f8f9fa; }
    @media (min-width: 768px) {
      .card-profile .card-footer {
        display: flex;
        align-items: center;
        justify-content: space-between; } }
    .card-profile .card-footer > a {
      display: block;
      padding: 15px;
      border-bottom: 1px solid #dee2e6;
      text-align: center; }
      @media (min-width: 768px) {
        .card-profile .card-footer > a {
          padding: 0 20px;
          border-bottom: 0;
          text-align: left; } }
    .card-profile .card-footer > div {
      display: flex; }
      @media (min-width: 768px) {
        .card-profile .card-footer > div {
          display: block; } }
      .card-profile .card-footer > div a {
        flex: 1;
        text-align: center;
        display: inline-block;
        padding: 12px 20px;
        border-left: 1px solid #dee2e6; }
        .card-profile .card-footer > div a:first-child {
          border-left-width: 0; }
          @media (min-width: 768px) {
            .card-profile .card-footer > div a:first-child {
              border-left-width: 1px; } }

/***** DATEPICKER *****/
.ui-datepicker {
  background-color: #fff;
  border: 1px solid #ced4da;
  font-family: inherit;
  font-size: inherit;
  padding: 10px;
  margin: 1px 0 0;
  display: none;
  width: auto !important;
  z-index: 5 !important; }
  .ui-datepicker .ui-datepicker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: #868ba1;
    padding: 0 0 5px;
    letter-spacing: 1px;
    border: 0;
    background-color: transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
    .ui-datepicker .ui-datepicker-header .ui-datepicker-next,
    .ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
      text-indent: -99999px;
      color: #6c757d;
      top: 1px; }
      .ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
      .ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
        font-size: 16px;
        font-family: 'FontAwesome';
        position: absolute;
        top: -4px;
        text-indent: 0; }
      .ui-datepicker .ui-datepicker-header .ui-datepicker-next:hover::before, .ui-datepicker .ui-datepicker-header .ui-datepicker-next:focus::before,
      .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:hover::before,
      .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:focus::before {
        color: #343a40; }
    .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
      order: 3; }
      .ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
        right: 5px;
        content: '\f105'; }
    .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
      left: 5px;
      content: '\f104'; }
    .ui-datepicker .ui-datepicker-header .ui-datepicker-next-hover,
    .ui-datepicker .ui-datepicker-header .ui-datepicker-prev-hover {
      color: #495057;
      cursor: pointer;
      top: 1px;
      border: 0;
      background-color: transparent; }
  .ui-datepicker .ui-datepicker-title {
    color: #5B93D3; }
  .ui-datepicker .ui-datepicker-calendar {
    margin: 0;
    background-color: transparent;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
    .ui-datepicker .ui-datepicker-calendar th {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 1px;
      padding: 6px 10px;
      color: #a3a7b7; }
      @media (max-width: 320px) {
        .ui-datepicker .ui-datepicker-calendar th {
          padding: 4px 0;
          letter-spacing: normal; } }
    .ui-datepicker .ui-datepicker-calendar td {
      border: 1px solid #fff;
      padding: 0;
      background-color: #fff;
      text-align: right; }
      .ui-datepicker .ui-datepicker-calendar td:last-child {
        border-right: 0; }
      .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-other-month .ui-state-default {
        color: #ccc; }
    .ui-datepicker .ui-datepicker-calendar td span,
    .ui-datepicker .ui-datepicker-calendar td a {
      transition: all 0.2s ease-in-out;
      padding: 5px;
      background-color: #f8f9fa;
      color: #868ba1;
      padding: 6px 10px;
      display: block;
      font-weight: 400;
      font-size: 12px;
      border: 0;
      border-radius: 1px; }
      @media screen and (prefers-reduced-motion: reduce) {
        .ui-datepicker .ui-datepicker-calendar td span,
        .ui-datepicker .ui-datepicker-calendar td a {
          transition: none; } }
    .ui-datepicker .ui-datepicker-calendar td a:hover {
      background-color: #f0f2f7;
      color: #868ba1; }
    .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
      background-color: #f0f2f7;
      color: #868ba1; }

.ui-datepicker-multi .ui-datepicker-group {
  padding-right: 15px;
  width: auto;
  float: left; }
  .ui-datepicker-multi .ui-datepicker-group .ui-datepicker-title {
    margin: auto; }
  .ui-datepicker-multi .ui-datepicker-group .ui-datepicker-prev::before {
    left: 10px; }
  .ui-datepicker-multi .ui-datepicker-group .ui-datepicker-next::before {
    right: 10px; }
  .ui-datepicker-multi .ui-datepicker-group table {
    margin: 0; }

.ui-datepicker-multi .ui-datepicker-group-last {
  padding-right: 0; }

.ui-datepicker-inline {
  max-width: 270px;
  border-color: #ced4da; }

/* #################### DATEPICKER SKINS #################### */
.fc-datepicker.bd-0 .ui-datepicker {
  border: 0; }

.fc-datepicker-color .ui-datepicker {
  border: 0; }

.fc-datepicker-color .ui-datepicker-title {
  color: #fff; }

.fc-datepicker-color .ui-datepicker-header .ui-datepicker-next,
.fc-datepicker-color .ui-datepicker-header .ui-datepicker-prev {
  color: rgba(255, 255, 255, 0.5); }
  .fc-datepicker-color .ui-datepicker-header .ui-datepicker-next.ui-state-hover::before,
  .fc-datepicker-color .ui-datepicker-header .ui-datepicker-prev.ui-state-hover::before {
    color: #fff; }

.fc-datepicker-color .ui-datepicker-calendar th {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif; }

.fc-datepicker-color .ui-datepicker-calendar td {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2); }
  .fc-datepicker-color .ui-datepicker-calendar td:last-child {
    border-right: 1px solid rgba(255, 255, 255, 0.2); }
  .fc-datepicker-color .ui-datepicker-calendar td.ui-datepicker-other-month .ui-state-default {
    color: rgba(255, 255, 255, 0.5); }
  .fc-datepicker-color .ui-datepicker-calendar td span,
  .fc-datepicker-color .ui-datepicker-calendar td a {
    background-color: transparent;
    color: #fff; }
  .fc-datepicker-color .ui-datepicker-calendar td a:hover,
  .fc-datepicker-color .ui-datepicker-calendar td a:focus {
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff; }

.fc-datepicker-color .ui-datepicker-calendar .ui-datepicker-today a {
  background-color: rgba(0, 0, 0, 0.15);
  color: #fff; }

.fc-datepicker-primary .ui-datepicker {
  background-color: #1b84e7; }

.fc-datepicker-success .ui-datepicker {
  background-color: #23BF08; }

.fc-datepicker-warning .ui-datepicker {
  background-color: #F49917; }

.fc-datepicker-danger .ui-datepicker {
  background-color: #dc3545; }

.fc-datepicker-info .ui-datepicker {
  background-color: #5B93D3; }

.fc-datepicker-indigo .ui-datepicker {
  background-color: #6610f2; }

.fc-datepicker-purple .ui-datepicker {
  background-color: #6f42c1; }

.fc-datepicker-pink .ui-datepicker {
  background-color: #e83e8c; }

.fc-datepicker-teal .ui-datepicker {
  background-color: #1CAF9A; }

.fc-datepicker-orange .ui-datepicker {
  background-color: #f27510; }

.fc-datepicker-dark .ui-datepicker {
  background-color: #343a40; }



.wd-1 {
  width: 1px; }

.wd-2 {
  width: 2px; }

.wd-3 {
  width: 3px; }

.wd-4 {
  width: 4px; }

.wd-6 {
  width: 6px; }

.wd-7 {
  width: 7px; }

.wd-8 {
  width: 8px; }

.wd-9 {
  width: 9px; }

.wd-16 {
  width: 16px; }

.wd-32 {
  width: 32px; }

.wd-36 {
  width: 36px; }

.wd-48 {
  width: 48px; }

.wd-64 {
  width: 64px; }

.wd-72 {  
  width: 72px; }

.wd-170 {
  width: 170px; }

.wd-180 {
  width: 180px; }

.wd-190 {
  width: 190px; }

.wd-220 {
  width: 220px; }

.wd-230 {
  width: 230px; }

.mx-wd-1350 {
  max-width: 1350px; }

